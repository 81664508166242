import React, { useState } from "react";
import Logo from "../images/basic/logo_pega.png";
import Gear from "../images/basic/settings-gear-icon1.png";

//  TODO:
//  pictures need to be local to this, not from training.openspan
//  update links
const TopMenu = ({toggleLogIn, initData, changeActive}) => {
    const [showMenu, setShowMenu] = useState(false);
    let timeoutId;

    const confirm = () => {
        const reset = window.confirm('Are you sure you want to reset the data? All edits you have made will be deleted.');
        if(reset){
            initData(true);
            return false;
        } else { 
            return false; 
        }
    }

    return (
    <div className="menu" id="load_header" align="center">
            <div id="inner-header">
                <div id="logo">
                    <span className="link" onClick={() => changeActive("invoice")}>
                        <img 
                            src={Logo}
                            alt="Pegasystems Logo"
                            border="0" width="100" height="47" />
                    </span>
                </div>
                <div id="account">
                    <div id="user_information_account">
                        <div style={{float: "right", height: "23px", paddingTop: "3px"}}>
                            <ul>
                                <li id="profile_menu" 
                                    onMouseEnter={() => {clearTimeout(timeoutId); setShowMenu(true)}}
                                    onMouseLeave={() => {timeoutId = setTimeout(() => setShowMenu(false), 1000)}}  
                                    className={`${showMenu} && "active"`}
                                    style={{verticalAlign: "bottom"}} 
                                >
                                    <a className="menu_option" href="#" style={{height: "24px", float: "right"}}>
                                        <img alt="Settings"
                                            src={Gear}
                                            border="0" width="16" height="16" />
                                    </a>
                                    <div id="profile" style={{display: !showMenu ? "none": null}}className="submenu_options">
                                        <div className="submenu_option"><p
                                                onClick={confirm}>Reset Data</p></div>
                                        <div className="submenu_option"><p onClick={toggleLogIn}>Sign Out</p></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div style={{height: "30px", float: "right"}}><span
                                style={{fontSize: "13px", lineHeight: "12px"}}>Welcome, Admin</span></div>
                    </div>
                </div>
            </div>
            <div style={{height: "30px"}}>
                <div style={{margin: "0 auto", width: "900px", background: "#fff", height: "30px"}}></div>
            </div>
        </div>
)}

export default TopMenu;
