import React, { useState, useEffect, useRef } from "react";

//  TODO
//  pagination for >5
const EditInvoice = ({ invoices, payments, customer, cancel, states, deleteCustomer, update, customers, editInvoice }) => {
    const initialState = {
        first_name: "",
        last_name: "",
        company_name: "",
        street_address: "",
        customer_city: "",
        customer_state: "",
        zip_code: "",
        customer_phone: "",
        account_number: "",
        id: 0,
    }

    const first_name = useRef(null);
    const last_name = useRef(null);
    const company_name = useRef(null);
    const street_address = useRef(null);
    const customer_city = useRef(null);
    const customer_state = useRef(null);
    const zip_code = useRef(null);
    const customer_phone = useRef(null);
    const account_number = useRef(null);

    const [inputs, setInputs] = useState(initialState);
    const [failed, setFailed] = useState(false);
    const [customerInvoices, setCustomerInvoices] = useState([]);
    const [customerPayments, setCustomerPayments] = useState([]);
    const [totals, setTotals] = useState({
        invoices: 0,
        payments: 0,
    });

    useEffect(() => {
        const initInvoices = invoices.filter(inv => inv.customer_id === customer.id);
        const initPayments = payments.filter(pay => pay.customer_id === customer.id);
        setInputs(customer);
        setCustomerInvoices(initInvoices);
        setCustomerPayments(initPayments);
        setTotals({
            invoices: initInvoices.reduce((sum, curr) => sum + Number(curr.invoice_amount), 0),
            payments: initPayments.reduce((sum, curr) => sum + Number(curr.amount), 0),
        })
    }, [customer, invoices, payments]);

    useEffect(() => {
        // validate fields on change
        if (!validate(inputs)) {
            setFailed(true);
        } else {
            setFailed(false);
        }

    }, [inputs]);

    const handleChange = () => {
        setInputs({
            first_name: first_name.current.value,
            last_name: last_name.current.value,
            company_name: company_name.current.value,
            street_address: street_address.current.value,
            customer_city: customer_city.current.value,
            customer_state: customer_state.current.value,
            zip_code: zip_code.current.value,
            customer_phone: customer_phone.current.value,
            account_number: account_number.current.value,
            id: inputs.id,
        });
    }

    // validate input fields
    const validate = fields => {
        for (let key in fields) {
            if (!fields[key]) {
                setFailed(true);
                return false;
            }
        }
        return true;
    }

    const remove = e => {
        e.preventDefault();
        e.stopPropagation();
        deleteCustomer(customer.id);
    }

    const submit = e => {
        e.preventDefault();
        const values = {
            first_name: first_name.current.value,
            last_name: last_name.current.value,
            company_name: company_name.current.value,
            street_address: street_address.current.value,
            customer_city: customer_city.current.value,
            customer_state: customer_state.current.value,
            zip_code: zip_code.current.value,
            customer_phone: customer_phone.current.value,
            account_number: account_number.current.value,
            id: inputs.id,
        };
        if (!validate(values)) return;
        values.customer_name = values.first_name + " " + values.last_name;
        //console.log(customers.concat(values))
        update({
            customers: customers.map(cust => cust.id === customer.id ? values : cust)
        });
        cancel();
    }

    return (
        <div className="inner_container">
            <div id="edit_customer" className="border_content" style={{ height: "auto", width: "410px" }}>
                <span id="title_invoice" style={{ width: "390px" }}><b>Customer</b></span>
                <div style={{ margin: "15px 0", float: "left", width: "100%" }}>
                    <form id="form1" onSubmit={submit}>
                        <ul style={{ width: "410px", padding: "0 0 0 20px" }}>
                            <li className="cust_li_new_size">
                                <span style={{ float: "left", width: "90px" }}>First Name</span>
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    <input
                                        className={`input_long_size ${!inputs.first_name && failed && "mandatory"}`}
                                        type="text"
                                        name="first_name"
                                        id="first_name"
                                        alt="First Name"
                                        onChange={handleChange}
                                        value={inputs.first_name}
                                        ref={first_name}
                                        autoComplete="off" />
                                </div>
                            </li>
                            <li className="cust_li_new_size">
                                <span style={{ float: "left", width: "90px" }}>Last Name</span>
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    <input
                                        className={`input_long_size ${!inputs.last_name && failed && "mandatory"}`}
                                        type="text"
                                        name="last_name"
                                        id="last_name"
                                        alt="Last Name"
                                        onChange={handleChange}
                                        value={inputs.last_name}
                                        ref={last_name}
                                        autoComplete="off" />
                                </div>
                            </li>
                            <li className="cust_li_new_size">
                                <span style={{ float: "left", width: "90px" }}>Company</span>
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    <input
                                        className={`input_long_size ${!inputs.company_name && failed && "mandatory"}`}
                                        type="text"
                                        alt="Company"
                                        name="company_name"
                                        id="company_name"
                                        onChange={handleChange}
                                        value={inputs.company_name}
                                        ref={company_name}
                                        autoComplete="off" />
                                </div>
                            </li>
                            <li className="cust_li_new_size">
                                <span style={{ float: "left", width: "90px" }}>Street Address</span>
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    <input
                                        className={`input_long_size ${!inputs.street_address && failed && "mandatory"}`}
                                        type="text"
                                        alt="Street Address"
                                        name="street_address"
                                        id="street_address"
                                        onChange={handleChange}
                                        value={inputs.street_address}
                                        ref={street_address}
                                        autoComplete="off" />
                                </div>
                            </li>
                            <li className="cust_li_new_size">
                                <span style={{ float: "left", width: "90px" }}>City</span>
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    <input
                                        className={`input_long_size ${!inputs.customer_city && failed && "mandatory"}`}
                                        type="text"
                                        alt="City"
                                        name="customer_city"
                                        id="customer_city"
                                        onChange={handleChange}
                                        value={inputs.customer_city}
                                        ref={customer_city}
                                        autoComplete="off" />
                                </div>
                            </li>
                            <li className="cust_li_new_size">
                                <span style={{ float: "left", width: "90px" }}>State</span>
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    <select
                                        className={`${!inputs.customer_state && failed && "mandatory"}`}
                                        alt="State"
                                        name="customer_state"
                                        id="customer_state"
                                        value={inputs.customer_state}
                                        ref={customer_state}
                                        onChange={handleChange}
                                    >
                                        <option value=""></option>
                                        {states.map(state => (
                                            <option
                                                key={state.id}
                                                value={state.state_code}
                                            >
                                                {state.state_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </li>
                            <li className="cust_li_new_size">
                                <span style={{ float: "left", width: "90px" }}>ZIP Code</span>
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    <input
                                        className={`input_long_size ${!inputs.zip_code && failed && "mandatory"}`}
                                        type="text"
                                        alt="Zip Code"
                                        name="zip_code"
                                        id="zip_code"
                                        maxLength="5"
                                        onChange={handleChange}
                                        value={inputs.zip_code}
                                        ref={zip_code}
                                        autoComplete="off" />
                                </div>
                            </li>
                            <li className="cust_li_new_size">
                                <span style={{ float: "left", width: "90px" }}>Phone</span>
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    <input
                                        className={`input_long_size ${!inputs.customer_phone && failed && "mandatory"}`}
                                        type="text" alt="Phone"
                                        name="customer_phone"
                                        maxLength="12"
                                        id="customer_phone"
                                        onChange={handleChange}
                                        value={inputs.customer_phone}
                                        ref={customer_phone}
                                        autoComplete="off" />
                                </div>
                            </li>
                            <li className="cust_li_new_size">
                                <span style={{ float: "left", width: "90px" }}>CRM Account</span>
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    <input
                                        className={`input_long_size ${!inputs.account_number && failed && "mandatory"}`}
                                        type="text"
                                        alt="CRM Account"
                                        name="account_number"
                                        id="account_number"
                                        onChange={handleChange}
                                        value={inputs.account_number}
                                        ref={account_number}
                                        autoComplete="off" />
                                </div>
                            </li>
                            <li className="cust_li_new_size">
                                <div style={{ float: "left", width: "230px", marginTop: "-3px" }}>
                                    <input id="login_button" type="submit" alt="Update" value="Update"
                                        name="Update" disabled={failed} />
                                    <input id="login_button" type="submit" onClick={remove} name="Remove"
                                        value="Remove" />
                                    <span className="link" style={{ marginLeft: "10px" }} onClick={cancel}
                                    >Cancel</span>
                                </div>
                                <div id="mandatory_fields" style={{ margin: "-2px 0 0 0" }}>
                                    {failed && <span>Complete required fields.</span>}
                                </div>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
            <div id="balances" className="border_content"
                style={{ height: "454px", marginLeft: "30px", width: "186px" }}>
                <span id="title_invoice" style={{ width: "166px" }}><b>Balances</b></span>
                <span id="title_invoice" className="sub-titles-balance"><b>Receivables</b></span>
                <div className="balance-subsection">
                    <p>
                        <span>Total Due: <b style={{ float: "right" }}>{(totals.invoices - totals.payments).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}</b></span>
                        <br /><br />
                        <span>Past Due: <b>$0.00</b></span>
                    </p>
                </div>
                <span id="title_invoice" className="sub-titles-balance"><b>Revenue</b></span>
                <div className="balance-subsection">
                    <p>
                        <span>Total Revenue: <b>{totals.invoices.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}</b></span>
                    </p>
                </div>
                <span id="title_invoice" className="sub-titles-balance"><b>Payments</b></span>
                <div className="balance-subsection">
                    <p>
                        <span>Total Payments: <b>{totals.payments.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}</b></span>
                    </p>
                </div>
            </div>
            <div id="list_invoices" className="border_content">
                <div style={{ float: "left", margin: "15px 0", width: "100%" }}>
                    <div id="paging_container8" className="border_content"
                        style={{ float: "left", height: "218px", margin: "0 26px", width: "574px" }}>
                        <div id="title_invoices"><span style={{ width: "155px" }}><b>Invoice
                            Number</b></span><span style={{ width: "155px" }}><b>Date</b></span><span
                                style={{ width: "155px" }}><b>Amount</b></span></div>
                        {customerInvoices.length ?
                            <ul id="invoice_list" className="contentli">
                                {customerInvoices.map(inv => (
                                    <li style={{ width: "552px", display: "list-item" }} key={inv.id}>
                                        <span
                                            style={{ width: "155px" }}
                                            className="link"
                                            onClick={() => editInvoice(inv.invoice_number)}
                                        >{inv.invoice_number}</span>
                                        <span style={{ width: "155px" }}>{inv.invoice_date}</span>
                                        <span className="mount_right_table">{Number(inv.invoice_amount).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}</span>
                                        <hr />
                                    </li>
                                ))}
                            </ul>
                            :
                            <p style={{ color: "#0D4891", float: "left", textAlign: "center", width: "100%", fontSize: "16px", margin: "80px 0" }}>There are no invoices.</p>
                        }
                        <div id="footer_signin"
                            style={{ marginTop: "223px", position: "absolute", width: "auto" }}>
                            <p style={{ textAlign: "left", paddingLeft: "27px", paddingTop: "12px" }}>The above
                                table is structured using &#60;DIV&#62; tags.</p>
                        </div>
                        <div className="page_navigation"></div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default EditInvoice;