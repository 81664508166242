import React, { useEffect, useState, useRef, createRef } from "react";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'

//  TODO:
//  pagination for >5 (should be identical to invoices)
const Payments = ({ customers, invoices, payments, update }) => {
    const initialState = {
        payment_date: "",
        payment_method: "",
        payment_reference: "",
        payment_memo: "",
    };
    const [custState, setCustState] = useState([]);
    const [invState, setInvState] = useState([]);
    const [payState, setPayState] = useState([]);
    const [activeCustomer, setActiveCustomer] = useState(0);
    const [failed, setFailed] = useState(false);
    const [inputs, setInputs] = useState(initialState);
    const [payValues, setPayValues] = useState({});

    const active_cust = useRef(null);
    const payment_method = useRef(null);
    const payment_reference = useRef(null);
    const payment_memo = useRef(null);
    const payment_date = useRef(null);

    const payment_values = useRef({})

    useEffect(() => {
        setCustState(
            customers.map(cust => ({ ...cust })).sort((a, b) => (a.customer_name > b.customer_name) ? 1 : -1)
        );
    }, []);

    useEffect(() => {
        setInvState(
            invoices.filter(inv => inv.customer_id === activeCustomer)
            .map(inv => ({ ...inv }))
            .sort((a, b) => (a.invoice_number > b.invoice_number) ? 1 : -1)
        )
        setPayState(
            payments.filter(inv => inv.customer_id === activeCustomer)
                .map(pay => ({ ...pay }))
        )
    }, [activeCustomer, payments]);

    useEffect(() => {
        // if previously failed field validation
        // re-run check and clear failure if it passes
        if (failed) {
            if (validate(inputs)) {
                setFailed(false);
            }
        }
    }, [inputs]);

    const calculateBalance = invId => {
        let balance = 0;
        invState.some(inv => {
            if (invId === inv.id) {
                balance = inv.invoice_amount;
                return true;
            }
        });

        payState.forEach(pay => {
            balance -= pay.invoice_id === invId ? pay.amount : 0;
        });

        return balance;
    }

    const paymentChange = ({target}) => {
        setPayValues({ ...payValues, [target.name]: target.value})
    }

    const handleChange = () => {
        setInputs({
            ...inputs,
            payment_method: payment_method.current.value,
            payment_reference: payment_reference.current.value,
            payment_memo: payment_memo.current.value,
            payment_date: payment_date.current.value,
        });
    }

    // clear input fields
    const clear = () => {
        setActiveCustomer(0);
        setInputs(initialState);
        setFailed(false);
    }

    // validate input fields
    const validate = fields => {
        for(let key in fields) {
            if ( !fields[key] ) {
                setFailed(true);
                return false;
            }
        }
        return true;
    }

    // submit form
    // validates fields, then processes payment, updates state, clears fields
    const submit = e => {
        e.preventDefault();
        let filtered_payments = {}
        for (let inv of invState) {
            if (payment_values[inv.id].current.value) {
                console.log("inv", inv.id, "amount", payment_values[inv.id].current.value)
                filtered_payments[inv.id] = payment_values[inv.id].current.value
            }
        }
        const values = {
            ...inputs,
            payment_method: payment_method.current.value,
            payment_reference: payment_reference.current.value,
            payment_memo: payment_memo.current.value,
            payment_date: payment_date.current.value,
        };
        values.customer_id = parseInt(active_cust.current.value);
        if ( !validate(values) ) return;
        const newPayments = []
        for(let key in filtered_payments) {
            const payment = {...values}
            payment.invoice_id = parseInt(key);
            payment.amount = parseFloat(filtered_payments[key]);
            let tempDateArr = payment.payment_date.split("-");
            payment.payment_date = tempDateArr[1] + "/" + tempDateArr[2] + "/" +tempDateArr[0]
            newPayments.push(payment);
        }
        console.log("new payments", newPayments)
        update({ payments: payments.concat(newPayments) })
        clear();
    }

    return (
        <div className="inner_container">
            <div id="new_invoice" className="border_content">
                <span id="title_invoice"><b>Enter Payment</b></span>
                <div style={{ margin: "15px 0", float: "left", width: "100%" }}>
                    <form id="form1" onSubmit={submit}>
                        <ul>
                            <li><span style={{ float: "left", width: "260px" }}>Customer Making
                                Payment</span><span
                                    style={{ float: "left", width: "260px", marginLeft: "25px" }}>Date</span></li>
                            <li>
                                <div style={{ float: "left" }}>
                                    <select name="payment_customer" id="payment_customer"
                                        className={`customer_list ${!activeCustomer && failed && "mandatory"}`}
                                        value={activeCustomer}
                                        ref={active_cust}
                                        onChange={(e) => setActiveCustomer(parseInt(e.target.value))}
                                    >
                                        <option id="0" value="0"></option>
                                        {custState.map(cust => (
                                            <option 
                                                key={cust.id} 
                                                value={cust.id} 
                                                id={cust.id}>
                                            {cust.customer_name}
                                            </option>
                                        ))}

                                    </select>
                                </div>
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    
                                    <input 
                                        className={`input_long_size ${!inputs.payment_date && failed && "mandatory"}`} 
                                        type="date" 
                                        alt="Date"
                                        id="payment_date" 
                                        name="payment_date"
                                        value={inputs.payment_date}
                                        ref={payment_date}
                                        onChange={handleChange} autoComplete="off" />
                                </div>
                            </li>
                            <li><span style={{ float: "left", width: "260px" }}>Payment Method</span><span
                                style={{ float: "left", width: "260px", marginLeft: "25px" }}>Reference</span>
                            </li>
                            <li>
                                <div style={{ float: "left" }}>
                                    <select name="payment_method" id="payment_method" value={inputs.payment_method} ref={payment_method}
                                        className={`customer_list ${!inputs.payment_method && failed && "mandatory"}`} onChange={handleChange}>
                                        <option value=""></option>
                                        <option value="Cash">Cash</option>
                                        <option value="Check">Check</option>
                                        <option value="Credit Card">Credit Card</option>
                                    </select>
                                </div>
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    <input 
                                        className={`input_long_size ${!inputs.payment_reference && failed && "mandatory"}`} 
                                        type="text" 
                                        alt="Reference"
                                        id="payment_reference" 
                                        name="payment_reference"
                                        value={inputs.payment_reference}
                                        ref={payment_reference}
                                        onChange={handleChange} autoComplete="off" />
                                </div>
                            </li>
                            <li><span style={{ float: "left", width: "400px" }}>Payment Memo</span></li>
                            <li>
                                <textarea rows="2"
                                    style={{ resize: "none", float: "left", width: "540px", fontSize: "12px" }}
                                    className={`${!inputs.payment_memo && failed && "mandatory"}`} 
                                    name="payment_memo" 
                                    id="payment_memo"
                                    value={inputs.payment_memo}
                                    ref={payment_memo}
                                    onChange={handleChange}></textarea>
                                <div id="mandatory_fields"
                                    style={{ margin: "5px 5px -20px 0px", float: "right" }}>
                                    <span>{failed && "Complete required fields"}</span>
                                    {/* <p>{failed && "You must apply the payment to at least one invoice."}</p> */}
                                </div>
                            </li>
                            <li>
                                <div style={{ float: "left", margin: "15px 0 0 0", width: "100%" }}
                                    id="invoice_payment_list">
                                    <div id="paging_container" className="border_content"
                                        style={{ float: "left", minHeight: "236px", overflow: "auto"}}>
                                        <div id="title_invoices">
                                            <span
                                                style={{ width: "108px" }}><b>Invoice Number</b></span>
                                            <span
                                                style={{ width: "108px" }}><b>Date</b></span>
                                            <span
                                                style={{ width: "108px", textAlign: "right" }}><b>Balance Due</b></span>
                                            <span><b>Amount</b></span>
                                        </div>
                                        { activeCustomer !== 0 ? (
                                        <table id="invoice_list" className="contentli">
                                            <thead>
                                                <tr id="0" style={{ display: "none" }}
                                                    className="title_table_selected">
                                                    <th>Invoice Number</th>
                                                    <th>Date</th>
                                                    <th>Balance Due</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    invState.length ?
                                                    invState.map((inv, i)=> {
                                                        payment_values[inv.id] = createRef();
                                                        return (
                                                        <tr key={inv.id} id={inv.id} style={{ display: "block" }}>
                                                            <td><span style={{ width: "110px" }}>{inv.invoice_number}</span></td>
                                                            <td><span style={{ width: "110px" }}>{inv.invoice_date}</span></td>
                                                            <td><span
                                                                style={{ width: "110px !important", margin: "0 24px 0 12px" }}
                                                                id={`total${inv.id}`} className="mount_right">
                                                                {calculateBalance(inv.id).toLocaleString('en-US', {
                                                                    style: 'currency',
                                                                    currency: 'USD',
                                                                })}</span></td>
                                                            <td><span style={{ margin: "0 37px 0 0" }}
                                                                className="mount_right">
                                                                <input disabled={calculateBalance(inv.id) === 0} className="list_input"
                                                                    style={{ padding: "0 3px 0 5px", height: "24px" }}
                                                                    type="text" alt="Discount"
                                                                    name={inv.id}
                                                                    ref={payment_values[inv.id]}
                                                                    id={`amount_less${inv.id}`} 
                                                                    onChange={paymentChange}
                                                                    />
                                                            </span></td>
                                                        </tr>)}
                                                    )
                                                    :
                                                    <tr>
                                                        <td>
                                                            <p style={{
                                                                float: "left", 
                                                                textAlign: "center", 
                                                                width: "490px", 
                                                                fontSize: "16px", 
                                                                margin: "80px 0"
                                                            }}>There are no invoices for this customer.</p>
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>)
                                        :
                                        (<p style={{
                                            float: "left",
                                            textAlign: "center",
                                            width: "100%",
                                            fontSize: "16px",
                                            margin: "91px 0",
                                            color: "#0D4891"
                                        }}>Please select a customer.</p>)
                                    }
                                    </div>
                                    <div id="footer_signin" style={{ float: "right", width: "auto" }}>
                                        <p style={{ textAlign: "left" }}>The above table is structured using
                                            &#60;TABLE&#62; tags.</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div id="buttons_cancel_save" style={{ float: "left", marginTop: "-30px" }}>

                                    <input type="hidden" name="last_saved" id="last_saved" value="0" />
                                    <input id="login_button" type="submit" alt="Save" value="Save"
                                        name="Save" disabled={failed}/>
                                    <input type="hidden" name="qty_arrays" value="14" />

                                    <a className="link" style={{ marginLeft: "10px" }} href="#"
                                        onClick={clear}>Cancel</a>
                                </div>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Payments;
