import React, { useState, useEffect } from "react";
import NewInvoice from "./new-invoice";
import EditInvoice from "./edit-invoice";

//  TODO
//  edit functionality on link click
//  pagination for >5
const Invoice = ({ customers, invoices, update, payments, reset, setReset, invoiceNum }) => {
    const initialState = {
        id: 0,
        customer_id: 0,
        invoice_date: "",
        invoice_number: 0,
        invoice_memo: "",
        invoice_amount: 0,
    };
    const [invoice, setInvoice] = useState(initialState);
    const [editInvoice, setEditInvoice] = useState(false);
    
    useEffect(() => {
        if (reset) {
            cancel();
        }
        setReset(false);
    }, [reset]);

    useEffect(() => {
        if ( invoice.id ) {
            setEditInvoice(true);
        }
    }, [invoice]);

    useEffect(() => {
        if ( invoiceNum ) {
            findInvoice(invoiceNum)
        }
    }, [invoiceNum])

    const cancel = () => {
        setInvoice(initialState);
        setEditInvoice(false);
    }

    const deleteInvoice = () => {
        // logic to delete invoice
        update({
            payments: payments.filter(pay => pay.invoice_id !== invoice.id),
            invoices: invoices.filter(inv => inv.id !== invoice.id),
        });
        cancel();
    }

    const findInvoice = invoiceNum => {
        invoices.some(inv => {
            if (inv.invoice_number === invoiceNum) {
                setInvoice(inv);
                return true;
            }
        });
    }

    return editInvoice ? 
        <EditInvoice
            payments={payments}
            customers={customers}
            invoice={invoice}
            deleteInvoice={deleteInvoice}
            cancel={cancel} 
        /> : 
        <NewInvoice 
            customers={customers}
            invoices={invoices}
            findInvoice={findInvoice}
            update={update}
        />
}

export default Invoice;
