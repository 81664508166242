import React, { useState, useEffect, useRef, forwardRef } from "react";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'

//  TODO
//  edit functionality on link click
//  pagination for >5
const NewInvoice = ({ customers, invoices, findInvoice, update }) => {
    const initialState = {
        id: 1,
        customer_id: 0,
        invoice_date: "",
        invoice_number: 50001,
        invoice_memo: "",
        invoice_amount: 0,
    };
    const [failed, setFailed] = useState(false);
    const [custState, setCustState] = useState([]);
    const [invState, setInvState] = useState([]);
    const [inputs, setInputs] = useState(initialState);

    const customer_id = useRef(null);
    const invoice_date = useRef(null);
    const invoice_number = useRef(null);
    const invoice_memo = useRef(null);
    const invoice_amount = useRef(null);

    useEffect(() => {
        setCustState(
            customers.map(cust => ({ ...cust })).sort((a, b) => (a.customer_name > b.customer_name) ? 1 : -1)
        );
    }, [customers]);

    useEffect(() => {
        setInvState(
            invoices.map(inv => ({ ...inv })).sort((a, b) => (a.invoice_number > b.invoice_number) ? 1 : -1)
        );

        if (invoices.length) {
            setInputs({
                ...inputs,
                invoice_number: invoices[invoices.length - 1].invoice_number + 1,
                id: invoices.reduce(function (prev, current) {
                    return (prev.id > current.id) ? prev.id : current.id
                }, invoices[0].id) + 1
            });
        }

    }, [invoices]);

    useEffect(() => {
        // if previously failed field validation
        // re-run check and clear failure if it passes

        if (failed) {
            if (validate(inputs)) {
                setFailed(false);
            }
        }
    }, [inputs]);


    const findCustomerName = cust_id => {
        let name = "";
        custState.some(cust => {
            if (cust.id === cust_id) {
                name = cust.customer_name;
                return true;
            }
        });

        return name;
    }

    const handleChange = () => {
        setInputs({
            ...inputs,
            customer_id: customer_id.current.value,
            invoice_memo: invoice_memo.current.value,
            invoice_amount: invoice_amount.current.value,
            invoice_date: invoice_date.current.value,
        });
    }

    const clear = () => {
        if (invoices.length) {
            setInputs({
                ...inputs,
                invoice_number: invoices.reduce(function (prev, current) {
                    return (prev.invoice_number > current.invoice_number) ? prev.invoice_number : current.invoice_number
                }) + 1,
                id: invoices.reduce(function (prev, current) {
                    return (prev.id > current.id) ? prev.id : current.id
                }) + 1
            });
        } else {
            setInputs(initialState);
        }
        setFailed(false);
    }

    // validate input fields
    const validate = fields => {
        for (let key in fields) {
            if (!fields[key]) {
                console.log("failed on", key);
                setFailed(true);
                return false;
            }
        }
        return true;
    }

    const submit = e => {
        e.preventDefault();
        const values = {
            ...inputs,
            customer_id: customer_id.current.value,
            invoice_memo: invoice_memo.current.value,
            invoice_amount: invoice_amount.current.value,
            invoice_date: invoice_date.current.value,
        };
        values.customer_id = parseInt(values.customer_id);
        if (!validate(values)) return;
        const tempDateArr = values.invoice_date.split("-")
        values.invoice_date = tempDateArr[1] + "/" + tempDateArr[2] + "/" +tempDateArr[0]
        update({ invoices: invoices.concat(values) })
        clear();
    }

    // const CustomInput = forwardRef(({ value, onClick, onChange, className }, ref) => (
    //     <input
    //       value={value}
    //       className={className}
    //       onClick={onClick}
    //       onChange={onChange}
    //       ref={ref}
    //     ></input>
    // ));

    return (
        <div className="inner_container" id="inner_container">
            <div id="new_invoice" className="border_content" style={{ height: "306px" }}>
                <span id="title_invoice"><b>Enter Invoice</b></span>
                <div style={{ margin: "17px 0", float: "left", width: "100%" }}>
                    <form id="form1" onSubmit={submit}>
                        <ul>
                            <li><span style={{ float: "left", width: "260px" }}>Customer</span><span
                                style={{ float: "left", width: "260px", marginLeft: "25px" }}>Date</span></li>
                            <li>
                                <div style={{ float: "left" }}>
                                    <select name="customer_id" id="customer_id"
                                        className={`customer_list ${!inputs.customer_id && failed && "mandatory"}`}
                                        value={inputs.customer_id}
                                        ref={customer_id}
                                        onChange={handleChange}
                                    >
                                        <option id="0" value="0"></option>
                                        {custState.map(cust => (
                                            <option
                                                key={cust.id}
                                                value={cust.id}
                                                id={cust.id}>
                                                {cust.customer_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    <input
                                        className={`input_long_size ${!inputs.invoice_date && failed && "mandatory"}`}
                                        type="date"
                                        alt="Date"
                                        name="invoice_date"
                                        id="invoice_date"
                                        value={inputs.invoice_date}
                                        ref={invoice_date}
                                        onChange={handleChange} />
                                </div>
                            </li>
                            <li><span style={{ float: "left", width: "260px" }}>Invoice Number</span><span
                                style={{ float: "left", width: "260px", marginLeft: "25px" }}>Amount</span>
                            </li>
                            <li>
                                <div style={{ float: "left" }}>
                                    <input
                                        style={{ background: "#E6E6E6" }}
                                        className="input_long_size"
                                        type="text" alt="Invoice"
                                        readOnly
                                        name="invoice_number"
                                        value={inputs.invoice_number}
                                        ref={invoice_number}
                                    />
                                </div>
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    <input
                                        className={`input_long_size ${!inputs.invoice_amount && failed && "mandatory"}`}
                                        type="number"
                                        alt="Amount"
                                        name="invoice_amount"
                                        id="invoice_amount"
                                        value={inputs.invoice_amount}
                                        ref={invoice_amount}
                                        onChange={handleChange} />
                                </div>
                            </li>
                            <li><span style={{ float: "left", width: "400px" }}>Invoice Memo</span></li>
                            <li>
                                <textarea
                                    rows="2"
                                    style={{ resize: "none", float: "left", width: "540px", fontSize: "12px" }}
                                    className={`${!inputs.invoice_memo && failed && "mandatory"}`}
                                    name="invoice_memo"
                                    id="invoice_memo"
                                    value={inputs.invoice_memo}
                                    ref={invoice_memo}
                                    onChange={handleChange}></textarea>
                            </li>
                            <li>
                                <div style={{ float: "left", width: "400px" }}>

                                    <input type="hidden" name="last_saved" id="last_saved" value="0" />
                                    <input id="login_button" type="submit" alt="Save" value="Save"
                                        name="Save" disabled={failed} />
                                    <span className="link" style={{ marginLeft: "10px" }}
                                        onClick={clear}>Reset</span>
                                </div>
                                <div id="mandatory_fields">
                                    <span>{failed && "Complete required fields."}</span>
                                </div>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
            <div id="list_invoices" className="border_content">
                <div style={{ float: "left", margin: "15px 0", width: "100%" }}>
                    <div id="paging_container8" className="border_content"
                        style={{ float: "left", minHeight: "218px", margin: "0 26px", width: "574px", overflow: "auto" }}>
                        <div id="title_invoices"><span><b>Date</b></span><span><b>Invoice
                            Number</b></span><span><b>Customer</b></span><span><b>Amount</b></span>
                        </div>
                        {invState.length ?
                            <ul id="invoice_list" className="contentli">
                                {invState.map(inv => (
                                    <li style={{ width: "552px" }} key={inv.id}>
                                        <span>{inv.invoice_date}</span>
                                        <span className="link" onClick={() => findInvoice(inv.invoice_number)}>{inv.invoice_number}</span>
                                        <span>{findCustomerName(inv.customer_id)}</span>
                                        <span className="mount_right">{Number(inv.invoice_amount).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}</span>
                                        <hr />
                                    </li>
                                ))}
                            </ul>
                            :
                            <p style={{ color: "#0D4891", float: "left", textAlign: "center", width: "100%", fontSize: "16px", margin: "80px 0" }}>There are no invoices.</p>
                        }


                        <div id="footer_signin"
                            style={{ marginTop: "15px", position: "relative", width: "auto" }}>
                            <p style={{ textAlign: "left", paddingLeft: "27px", paddingTop: "12px" }}>The above
                                table is structured using &#60; DIV&#62; tags.</p>
                        </div>
                        <div className="page_navigation">
                            {/* <a className="previous_link  no_more" href="">Prev</a>
                            <span className="ellipse less" style="display: none;"></span>
                            <a className="page_link  first active_page" href="" longdesc="0" style="display: block;">1</a>
                            <a className="page_link  last" href="" longdesc="1" style="display: block;">2</a>
                            <span className="ellipse more" style="display: none;"></span>
                            <a className="next_link" href="">Next</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewInvoice;
