import React from "react";
import "../css/login.css";
import Logo from "../images/basic/logo_pega.png";

//  TODO
//  restrict via same un/pass > 4
//  onKeyUp functionality
const LogIn = ({ toggleLogIn }) => {

    const submit = e => {
        e.preventDefault();
        toggleLogIn();
    }

    return (
        <div>
            <div id="signin_bar">
                <div id="image_pretzel_os">

                    <img 
                        src={Logo}
                        border="0" 
                        width="100" 
                        height="47" 
                        alt="Pegasystems Logo" 
                    />
                </div>
            </div>
            <div className="wrapper_sign_in">
                <div id="inline1">
                    <div id="header_signin_warning">
                        <div>
                            Enter any four characters in the user name and password fields to log into this training website.
                            <br />
                            <br />
                            Important: Do not enter the secure credentials that you use to sign in to your organization's network or personal accounts.
                        </div>
                    </div>
                    <div className="loginform">
                        <h1>Sign in to Pega Robotic Automation Finance</h1>

                        <form onSubmit={submit}>
                            <table style={{margin:"0px auto 0 -10px"}} id="login_section" cellPadding="10" align="left">
                                <tbody>
                                    <tr>
                                        <td>
                                            <input 
                                                className="input_text" 
                                                type="text" 
                                                name="user_name" 
                                                id="user_name" 
                                                size="50" 
                                                placeholder="User Name" 
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>

                                            <input 
                                                className="input_text" 
                                                name="user_pass" 
                                                id="user_pass" 
                                                size="50" 
                                                placeholder="Password" 
                                                type="password" 
                                            />
                                        </td>
                                    </tr>
                                    <tr>

                                        <td><input id="login_button" type="submit" value="Sign In" /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <input type="hidden" name="openspan_version" value="3.1" />
                        </form>
                    </div>
                    <div id="footer_signin">
                        <p>This web site contains practice web pages you will use as you work through Pega Robotic Automation training exercises.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LogIn;