import './App.css';
import LogIn from './components/login';
import Main from './components/main';
import { useState } from 'react';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  const toggleLogIn = () => setLoggedIn(!loggedIn);

  return (
    <div className="App">
      { loggedIn ? 
        <Main
          toggleLogIn = {toggleLogIn} 
        /> : 
        <LogIn
          toggleLogIn = {toggleLogIn} 
        /> }
    </div>
  );
}

export default App;
