import React, { Fragment, useEffect, useState, useRef } from "react";

//  TODO:
//  Name links go to info/edit page
//      -   invoice links work the same as links from invoices
const NewCustomer = ({ states, customers, update, findCustomer }) => {
    const initialState = {
        first_name: "",
        last_name: "",
        company_name: "",
        street_address: "",
        customer_city: "",
        customer_state: "",
        zip_code: "",
        customer_phone: "",
        account_number: "",
    }
    const [custState, setCustState] = useState([]);
    const [failed, setFailed] = useState(false);
    const [inputs, setInputs] = useState({
        ...initialState,
        id: 0,
    });

    const first_name = useRef(null);
    const last_name = useRef(null);
    const company_name = useRef(null);
    const street_address = useRef(null);
    const customer_city = useRef(null);
    const customer_state = useRef(null);
    const zip_code = useRef(null);
    const customer_phone = useRef(null);
    const account_number = useRef(null);

    useEffect(() => {
        setCustState(
            customers.map(cust => ({ ...cust })).sort((a, b) => (a.customer_name > b.customer_name) ? 1 : -1)
        );
        setInputs({
            ...inputs,
            id: Math.max(...customers.map(o => o.id), -1) + 1
        });

    }, [customers]);

    useEffect(() => {
        // if previously failed field validation
        // re-run check and clear failure if it passes
        if (failed) {
            if (validate(inputs)) {
                setFailed(false);
            }
        }
    }, [inputs]);

    const handleChange = () => {
        setInputs({
            first_name: first_name.current.value,
            last_name: last_name.current.value,
            company_name: company_name.current.value,
            street_address: street_address.current.value,
            customer_city: customer_city.current.value,
            customer_state: customer_state.current.value,
            zip_code: zip_code.current.value,
            customer_phone: customer_phone.current.value,
            account_number: account_number.current.value,
            id: inputs.id,
        });
    }

    const clear = () => {
        setInputs({
            ...initialState,
            id: inputs.id,
        });
        setFailed(false);
    }

    // validate input fields
    const validate = fields => {
        for (let key in fields) {
            if (!fields[key]) {
                setFailed(true);
                return false;
            }
        }
        return true;
    }

    const submit = e => {
        e.preventDefault();
        const values = {
            first_name: first_name.current.value,
            last_name: last_name.current.value,
            company_name: company_name.current.value,
            street_address: street_address.current.value,
            customer_city: customer_city.current.value,
            customer_state: customer_state.current.value,
            zip_code: zip_code.current.value,
            customer_phone: customer_phone.current.value,
            account_number: account_number.current.value,
            id: inputs.id,
        };
        if (!validate(values)) return;
        values.customer_name = values.first_name + " " + values.last_name;
        //console.log(customers.concat(values))
        update({ customers: customers.concat(values) })
        clear();
    }

    return (
        <div className="inner_container" id="inner_container">
            <div id="new_customer" className="border_content" style={{ height: "auto" }}>
                <span id="title_invoice"><b>Add Customer</b></span>
                <div style={{ margin: "16px 0", float: "left", width: "100%" }}>
                    <form id="form1" onSubmit={submit}>
                        <ul>
                            <li>
                                <span style={{ float: "left", width: "260px" }}>First Name</span><span
                                    style={{ float: "left", width: "260px", marginLeft: "25px" }}>Last Name</span>
                                <div style={{ float: "left" }}>
                                    <input
                                        className={`input_long_size ${!inputs.first_name && failed && "mandatory"}`}
                                        type="text"
                                        name="first_name"
                                        id="first_name"
                                        alt="First Name"
                                        onChange={handleChange}
                                        value={inputs.first_name}
                                        ref={first_name}
                                        autoComplete="off" />
                                </div>
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    <input
                                        className={`input_long_size ${!inputs.last_name && failed && "mandatory"}`}
                                        type="text"
                                        name="last_name"
                                        id="last_name"
                                        alt="Last Name"
                                        onChange={handleChange}
                                        value={inputs.last_name}
                                        ref={last_name}
                                        autoComplete="off" />
                                </div>
                            </li>
                            <li>
                                <span style={{ float: "left", width: "260px" }}>Company</span><span
                                    style={{ float: "left", width: "260px", marginLeft: "25px" }}>Street
                                    Address</span>
                                <div style={{ float: "left" }}>
                                    <input
                                        className={`input_long_size ${!inputs.company_name && failed && "mandatory"}`}
                                        type="text"
                                        alt="Company"
                                        name="company_name"
                                        id="company_name"
                                        onChange={handleChange}
                                        value={inputs.company_name}
                                        ref={company_name}
                                        autoComplete="off" />
                                </div>
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    <input
                                        className={`input_long_size ${!inputs.street_address && failed && "mandatory"}`}
                                        type="text"
                                        alt="Street Address"
                                        name="street_address"
                                        id="street_address"
                                        onChange={handleChange}
                                        value={inputs.street_address}
                                        ref={street_address}
                                        autoComplete="off" />
                                </div>
                            </li>
                            <li>
                                <span style={{ float: "left", width: "260px" }}>City</span><span
                                    style={{ float: "left", width: "260px", marginLeft: "25px" }}>State</span>
                                <div style={{ float: "left" }}>
                                    <input
                                        className={`input_long_size ${!inputs.customer_city && failed && "mandatory"}`}
                                        type="text"
                                        alt="City"
                                        name="customer_city"
                                        id="customer_city"
                                        onChange={handleChange}
                                        value={inputs.customer_city}
                                        ref={customer_city}
                                        autoComplete="off" />
                                </div>
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    <select
                                        className={`${!inputs.customer_state && failed && "mandatory"}`}
                                        alt="State"
                                        name="customer_state"
                                        id="customer_state"
                                        value={inputs.customer_state}
                                        ref={customer_state}
                                        onChange={handleChange}
                                    >
                                        <option value=""></option>
                                        {states.map(state => (
                                            <option
                                                key={state.id}
                                                value={state.state_code}
                                            >
                                                {state.state_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </li>
                            <li>
                                <span style={{ float: "left", width: "260px" }}>ZIP Code</span><span
                                    style={{ float: "left", width: "260px", marginLeft: "25px" }}>Phone</span>
                                <div style={{ float: "left" }}>
                                    <input
                                        className={`input_long_size ${!inputs.zip_code && failed && "mandatory"}`}
                                        type="text"
                                        alt="Zip Code"
                                        name="zip_code"
                                        id="zip_code"
                                        maxLength="5"
                                        onChange={handleChange}
                                        value={inputs.zip_code}
                                        ref={zip_code}
                                        autoComplete="off" />
                                </div>
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    <input
                                        className={`input_long_size ${!inputs.customer_phone && failed && "mandatory"}`}
                                        type="text" alt="Phone"
                                        name="customer_phone"
                                        maxLength="12"
                                        id="customer_phone"
                                        onChange={handleChange}
                                        value={inputs.customer_phone}
                                        ref={customer_phone}
                                        autoComplete="off" />
                                </div>
                            </li>
                            <li>
                                <span style={{ float: "left", width: "100%" }}>CRM Account</span>
                                <div style={{ float: "left" }}>
                                    <input
                                        className={`input_long_size ${!inputs.account_number && failed && "mandatory"}`}
                                        type="text"
                                        alt="CRM Account"
                                        name="account_number"
                                        id="account_number"
                                        onChange={handleChange}
                                        value={inputs.account_number}
                                        ref={account_number}
                                        autoComplete="off" />
                                </div>
                            </li>
                            <li>
                                <span style={{ float: "left", width: "100%", height: "5px" }}></span>
                                <div style={{ float: "left", width: "400px" }}>

                                    <input type="hidden" name="last_saved" id="last_saved" value="0" />
                                    <input id="login_button" type="submit" alt="Save" value="Save"
                                        name="Save" disabled={failed} />
                                    <span className="link" style={{ marginLeft: "10px" }}
                                        onClick={clear}>Reset</span>
                                </div>
                                <div id="mandatory_fields" style={{ marginTop: "13px" }}>
                                    <span>{failed && "Complete required fields"}</span>
                                </div>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
            <div id="list_invoices" className="border_content" style={{ height: "auto" }}>
                <div style={{ float: "left", margin: "15px 0", width: "100%" }}>
                    <div id="" className="border_content"
                        style={{ float: "left", height: "auto", margin: "0 26px", width: "574px" }}>
                        <div id="title_invoices">
                            <span><b>Name</b></span><span><b>Account</b></span><span><b>Address</b></span><span
                                style={{ marginRight: "0px", width: "120px" }}></span></div>
                        <table id="invoice_list" className="contentli">
                            <tbody>
                                {custState.map(cust => (
                                    <Fragment key={cust.id}>
                                        <tr>
                                            <td>
                                                <span className="link" onClick={() => findCustomer(cust.id)}>{cust.customer_name}</span></td>
                                            <td>{cust.account_number}</td>
                                            <td style={{ width: "250px" }}>{cust.street_address}</td>
                                        </tr>
                                        <tr className="bottom_line_hr"></tr>
                                    </Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div id="footer_signin" style={{ width: "auto" }}>
                        <p style={{ textAlign: "left", paddingLeft: "55px", paddingTop: "12px" }}>The above
                            table is structured using &#60;TABLE&#62; tags.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewCustomer;
