import React, { useState, useEffect } from "react";

//  TODO
//  edit functionality on link click
//  pagination for >5
const EditInvoice = ({ invoice, payments, customers, cancel, deleteInvoice }) => {
    const [paymentTotal, setPaymentTotal] = useState(0);
    const [invoicePayments, setInvoicePayments] = useState([]);
    let i = 0;
    // calculate total of payments made against invoice and set invoice payments array
    useEffect(() => {
        const filteredPayments = payments.filter(pay => pay.invoice_id === invoice.id);
        
        setInvoicePayments(filteredPayments);
        setPaymentTotal(
            filteredPayments.reduce((sum, curr) => sum + Number(curr.amount), 0)
        );
    }, []);
    
    const submit = e => {
        e.preventDefault();
        if(window.confirm("Are you sure you want to remove the invoice?")) {
            deleteInvoice();
        }
    }

    return (
        <div className="inner_container">              
            <div id="new_invoice" className="border_content" style={{height: "583px"}}>
                <span id="title_invoice"><b>Invoice</b></span>
                <div style={{margin: "15px 0", float: "left", width: "100%"}}>
                    <form id="form1" onSubmit={submit}>
                        <ul>
                            <li><span style={{"float":"left","width":"260px"}}>Date</span><span
                                    style={{"float":"left","width":"260px","marginLeft":"25px"}}>Customer</span>
                            </li>
                            <li>
                                <div style={{float: "left"}}>
                                    <input readOnly style={{background: "#E6E6E6"}}
                                        className="input_long_size" type="text" id="datepicker"
                                        name="invoice_date" alt="Date" value="02/01/2022" />
                                </div>
                                <div style={{float: "left", marginLeft: "25px"}}>
                                    <select 
                                        readOnly 
                                        style={{background: "#E6E6E6"}}
                                        name="invoice_customer"
                                        value={invoice.customer_id}
                                    >
                                        <option id="0" value="0"></option>
                                        {customers.map(cust => (
                                            <option 
                                                key={cust.id} 
                                                value={cust.id} 
                                                id={cust.id}>
                                            {cust.customer_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </li>
                            <li><span style={{float: "left", width: "260px"}}>Invoice Number</span><span
                                    style={{float: "left", width: "260px", marginLeft: "25px"}}>Amount</span>
                            </li>
                            <li>
                                <div style={{float: "left"}}>
                                    <input readOnly style={{background: "#E6E6E6"}}
                                        className="input_long_size" type="text" alt="Invoice"
                                        name="invoice_number" value={invoice.invoice_number} />
                                </div>
                                <div style={{float: "left", marginLeft: "25px"}}>
                                    <input 
                                        readOnly 
                                        style={{background: "#E6E6E6"}}
                                        className="input_long_size" 
                                        type="text" 
                                        alt="Amount"
                                        name="invoice_amount" 
                                        id="invoice_amount"
                                        value={Number(invoice.invoice_amount).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}/>
                                </div>
                            </li>
                            <li>
                                <span style={{float: "left", width: "260px"}}>Invoice Memo</span><span
                                    style={{float: "left", width: "260px", marginLeft: "25px"}}>Payments</span>
                            </li>
                            <li>
                                <textarea 
                                    readOnly 
                                    rows="2"
                                    style={{resize: "none", float: "left", width: "256px", fontSize: "12px", height: "31px", marginBottom: "16px", background: "#E6E6E6"}}
                                    name="invoice_memo">{invoice.invoice_memo}</textarea>
                                <div className="balance-subsectioni"
                                    style={{float: "left", marginLeft: "25px", width: "255px"}}>
                                    <input 
                                        readOnly 
                                        style={{background: "#E6E6E6"}}
                                        className="input_long_size" 
                                        type="text" 
                                        alt="Payments"
                                        name="invoice_payments" 
                                        id="invoice_payments" 
                                        value={paymentTotal.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })} />
                                </div>
                            </li>
                            <li>
                                <div style={{float: "left", width: "260px"}}>

                                    <input type="hidden" name="id" value="9197" />
                                    <div className="contenttooltip">
                                        <div className="tooltip" style={{marginTop: "-70px"}}>Delete this
                                            invoice and all associated payments.</div>
                                        <input id="login_button" type="submit" name="Delete"
                                            value="Delete"
                                        />
                                    </div>
                                    <a className="link"
                                        style={{marginLeft: "10px", marginTop: "8px", float: "left"}} 
                                        onClick={cancel}
                                    >Cancel</a>
                                </div>
                                <div className="balance-subsectioni"
                                    style={{float: "left", marginLeft: "25px", width: "260px", height: "30px", lineHeight: "22px"}}>
                                    <p><span><b>Remaining Balance due: {(invoice.invoice_amount - paymentTotal).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}</b></span></p>
                                </div>
                            </li>
                        </ul>
                    </form>
                </div>
                <div style={{float: "left", margin: "15px 0", width: "100%"}}>
                    <div id="paging_container8" className="border_content"
                        style={{float: "left", height: "218px", margin: "0 26px", width: "574px"}}>
                        <div id="title_invoices"><span><b>Date</b></span><span><b>Payment
                                    Method</b></span><span><b>Reference</b></span><span><b>Amount</b></span>
                        </div>
                        {
                            invoicePayments.length ?
                            <ul id="invoice_list" className="contentli">
                                {invoicePayments.map(pay => (
                                    <li>
                                        <span /*className="link"*/>{pay.payment_date}</span>
                                        <span>{pay.payment_method}</span>
                                        <span>{pay.payment_reference}</span>
                                        <span className="mount_right">{pay.amount.toLocaleString('en-US', {
                                                                        style: 'currency',
                                                                        currency: 'USD',
                                                                    })}</span>
                                        <hr />
                                    </li> 
                                ))}
                            </ul> 
                            :
                            <p style={{float: "left", textAlign: "center", width: "100%", color: "#0D4891", fontSize: "16px", margin: "80px 0"}}>No payments have been made against this invoice.</p>
                        }
                        
                        <div id="footer_signin"
                            style={{marginTop: "223px", position: "absolute", width: "auto"}}>
                            <p style={{textAlign: "left", paddingLeft: "27px", paddingTop: "12px"}}>The above
                                table is structured using &#60;DIV&#62; tags.</p>
                        </div>
                        <div className="page_navigation"></div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default EditInvoice;