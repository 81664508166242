import React, { useState } from "react";
import "../css/purchases.css";

const Purchases = ({purchases}) => {
    const [selected, setSelected] = useState(1);

    return (
        <div className="inner_container" id="inner_container">
            <div id="new_customer" className="border_content" style={{border: "none"}}>
                <span id="title_invoice" style={{width: "610px"}}><b>Purchase Orders</b></span>
                <div style={{margin: "16px 0", float: "left", width: "100%"}}>
                    <div className="tabs">
                        {purchases.map(purch => (
                            <div 
                                className={`tab${selected === purch.id ? " selected" : ""}`} 
                                id={`${selected === purch.id && "selected"}`}
                                key={purch.id}
                            >
                                <input 
                                    type="radio" 
                                    id={`${"tab-" + purch.id}`} 
                                    name="tab-group-1"
                                    onClick={() => setSelected(purch.id)}
                                    checked={selected === purch.id}
                                />
                                <label for={`${"tab-" + purch.id}`} >{purch.po}</label>
                                <div className="content_tab">
                                    <p>
                                        <ul>
                                            <li>
                                                <span style={{float: "left", width: "260px"}}>Purchase Order: </span>
                                                <div style={{float: "left"}}>
                                                    {purch.po}</div>
                                            </li>
                                            <li>
                                                <span style={{float: "left", width: "260px"}}>Supplier: </span>
                                                <div style={{float: "left"}}>
                                                    <input className="input_long_size" type="text" alt="Supplier"
                                                        name="supplier" id="supplier"
                                                        autocomplete="off" defaultValue={purch.supplier} />
                                                </div>
                                            </li>
                                            <li>
                                                <span style={{float: "left", width: "260px"}}>Delivery Date: </span>
                                                <div style={{float: "left"}}>
                                                    <input className="input_long_size" type="text"
                                                        alt="Delivery Date" name="delivery_date"
                                                        id="delivery_date"
                                                        autocomplete="off" defaultValue={purch.delivery_date}/>
                                                </div>
                                            </li>
                                            <li>
                                                <span style={{float: "left", width: "260px"}}>Contact: </span>
                                                <div style={{float: "left"}}>
                                                    <input className="input_long_size" type="text" alt="Contact"
                                                        name="contact" id="contact" maxlength="5"
                                                        autocomplete="off" defaultValue={purch.contact} />
                                                </div>
                                            </li>
                                            <li>
                                                <span style={{float: "left", width: "260px"}}>Telephone: </span>
                                                <div style={{float: "left"}}>
                                                    <input className="input_long_size" type="text" alt="Telephone"
                                                        name="telephone" id="telephone"
                                                        autocomplete="off" defaultValue={purch.phone} />
                                                </div>
                                            </li>
                                            <li>
                                                <span style={{float: "left", width: "260px"}}>Order total: </span>
                                                <div style={{float: "left"}}>
                                                    <input className="input_long_size" type="text" alt="Order total"
                                                        name="order_total" id="order_total"
                                                        autocomplete="off" defaultValue={purch.total_order}/>
                                                </div>
                                            </li>
                                            <li>
                                                <span style={{float: "left", width: "260px"}}>Status: </span>
                                                <div style={{float: "left"}}>
                                                    <input className="input_long_size" type="text" alt="Status"
                                                        name="status" id="status"
                                                        autocomplete="off" defaultValue={purch.status} />
                                                </div>
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Purchases;
