import React, { useEffect, useState } from "react";
import api from "../utils/api";
import local from "../utils/local";
import TopMenu from "./top-menu";
import "../css/style.css";
import "../css/basic_style.css";
import "../css/basic.css";
import SideMenu from "./side-menu";
import Invoice from "./invoice";
import Payments from "./payments";
import Customers from "./customers";
import Purchases from "./purchases";

//  TODO
//  Add components
//  Fix css for content container (doesn't expand with content)
const Main = ({ toggleLogIn }) => {
    const [data, setData] = useState({
        invoices: [],
        customers: [],
        payments: [],
        purchaseOrders: [],
        states: [],
    });
    
    const [active, setActive] = useState("invoice");
    const [reset, setReset] = useState(true);
    const [invoiceNum, setInvoiceNum] = useState(0);

    const initData = (reset) => {

        if (reset) local.clearData();

        (async () => {
            let localData = local.getData();

            if (!localData) {
                const response = await api.getAll();
                local.saveData(response.data);
                localData = response.data;
            }
            //console.log("data", localData);
            setData(localData);
        })();
    }

    useEffect(initData, []);

    const update = newData => {
        const updated = {...data, ...newData};
        local.saveData(updated);
        setData(updated);
    }

    const changeActive = component => {
        setReset(true);
        setActive(component);
    }

    const editInvoice = invNum => {
        setReset(false);
        setInvoiceNum(invNum);
        setActive("invoice");
    }

    const activeComponent = () => {
        switch (active) {
            case "invoice":
                return <Invoice 
                    customers={data.customers}
                    invoices={data.invoices}
                    payments={data.payments}
                    reset={reset}
                    setReset={setReset}
                    update={update}
                    invoiceNum={invoiceNum}
                />;
            case "payments":
                return <Payments 
                    payments={data.payments}
                    customers={data.customers}
                    invoices={data.invoices}
                    update={update}
                />;
            case "customers":
                return <Customers 
                    states={data.states}
                    customers={data.customers}
                    invoices={data.invoices}
                    payments={data.payments}
                    reset={reset}
                    setReset={setReset}
                    update={update}
                    editInvoice={editInvoice}
                />;
            case "purchases":
                return <Purchases purchases={data.purchaseOrders} />;
            default:
                return <Invoice />;
        }
    }

    return (
        <div className="container" >
            <TopMenu
                toggleLogIn={toggleLogIn}
                initData={initData}
                changeActive={changeActive}
            />
            <div className="content_layout" align="center">
                <div className="content" id="content">
                    <SideMenu
                        active={active}
                        changeActive={changeActive}
                    />
                    <div className="content_home" id="load_content">
                        <div className="wrapper">
                            {activeComponent()}
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer" id="load_footer" align="center">
                <div className="content_footer">
                    <div className="copyright">
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;