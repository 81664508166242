import React from "react";

const SideMenu = ({active, changeActive}) => {
    return (
        <div className="side_menu" id="load_menu">
            <div id="inner-menu">
                <span id="title_menu"><b>ACME Finance</b></span>
                <div id="menu">
                    <ul>
                        <li id="acme_home" className={`${active === "invoice" && "active"}`}>
                            <span onClick={() => changeActive("invoice")}>Invoice</span>
                        </li>
                        <li id="payment_menu" className={`${active === "payments" && "active"}`}>
                            <span onClick={() => changeActive("payments")}>Payment</span>
                        </li>
                        <li id="customer_menu" className={`${active === "customers" && "active"}`}>
                            <span onClick={() => changeActive("customers")}>Customers</span>
                        </li>
                        <li id="purchase_menu" className={`${active === "purchases" && "active"}`}>
                            <span onClick={() => changeActive("purchases")}>Purchase Orders</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SideMenu;