import React, { useState, useEffect } from "react";
import NewCustomer from "./new-customer";
import EditCustomer from "./edit-customer";

//  TODO
//  edit functionality on link click
//  pagination for >5
const Customers = ({ payments, invoices, states, customers, update, reset, setReset, editInvoice }) => {
    const initialState = {
        first_name: "",
        last_name: "",
        company_name: "",
        street_address: "",
        customer_city: "",
        customer_state: "",
        zip_code: "",
        customer_phone: "",
        account_number: "",
        id: 0,
    }
    const [customer, setCustomer] = useState(initialState);
    const [editCustomer, setEditCustomer] = useState(false);

    useEffect(() => {
        if (reset) {
            cancel();
        }
        setReset(false);
    }, [reset]);

    useEffect(() => {
        if ( customer.id ) {
            setEditCustomer(true);
        }
    }, [customer]);

    const findCustomer = custId => {
        customers.some(cust => {
            if (cust.id === custId) {
                setCustomer(cust);
                return true;
            }
        });
    }

    const cancel = () => {
        setCustomer(initialState);
        setEditCustomer(false);
    }

    const deleteCustomer = () => {
        // logic to delete invoice
        update({
            payments: payments.filter(pay => pay.customer_id !== customer.id),
            invoices: invoices.filter(inv => inv.customer_id !== customer.id),
            customers: customers.filter(cust => cust.id !== customer.id),
        });
        cancel();
    }


    return editCustomer ? 
        <EditCustomer
            customer={customer}
            customers={customers}
            states={states}
            update={update}
            invoices={invoices}
            payments={payments}
            editInvoice={editInvoice}
            deleteCustomer={deleteCustomer}
            cancel={cancel}
        /> : 
        <NewCustomer 
            customers={customers}
            states={states}
            update={update}
            findCustomer={findCustomer}
        />
}

export default Customers;
